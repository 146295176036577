import React from "react"
import { graphql } from "gatsby"
import Container from "../../components/common/Container"
import GridTwo from "../../components/common/GridTwo"
import GridThree from "../../components/common/GridThree"
import CardPrint from "../../components/common/CardPrint"
import Layout from "../../components/layouts/Default"
import { H2 } from "../../components/typography/Headings"
import P from "../../components/typography/P"
import PageHeader from "../../components/common/PageHeader"
import Breadcrumb from "../../components/common/Breadcrumb"

export default function Page({ data, location }) {

  return (
    <Layout location={location} title="Kalender" desc="">
      <PageHeader title="Kalender" image={data.header} />
      <Breadcrumb pages={[{ name: "Verlag", to: "/verlag/" },{ name: "Kalender", to: "/verlag/kalender/" }]} />

      <Container className="space-y-8 md:space-y-16">
        <div>
          <div className="mb-8 md:mb-16">
            <H2 className="mb-5 font-display">NATURBLICK | Kalender</H2>
            <GridTwo>
              <div className="space-y-5">
                <P>
                  Kalender sind Blickfänger par excellence, nicht zuletzt durch
                  ihre ganzjährige Nutzung. Ohne Kalender läuft nichts: Timing,
                  Planung, Vermeiden von Stress. Sie stehen immer wieder im
                  Fokus der Aufmerksamkeit und sind allen digitalen Medien zum
                  Trotz kaum wegzudenken.
                </P>
              </div>
            </GridTwo>
          </div>
          <GridThree>
            {data.kalender.nodes.map((pub, i) => {
              return (
                <CardPrint
                  image={pub.remoteCoverImage}
                  title={pub.title}
                  link={`/verlag/kalender/${pub.title_slug}/`}
                  key={i}
                />
              )
            })}
          </GridThree>
        </div>

      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "pages/verlag/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(transformOptions: {cropFocus: ATTENTION}, width: 1600)
      }
    }
    kalender: allPublikationen(
      sort: { fields: date, order: DESC }
      filter: { art: { eq: "Kalender" } }
    ) {
      nodes {
        remoteCoverImage {
          childImageSharp {
            gatsbyImageData(transformOptions: {cropFocus: ATTENTION}, width: 1024)
          }
        }
        title
        title_slug
      }
    }
  }
`
